a {
  color: #f70404;
  text-decoration: none;
}
a:link {
  color: #f70404;
  text-decoration: none;
}
a:visited {
  color: #f70404;
  text-decoration: none;
}
a:active {
  color: #f70404;
  text-decoration: none;
}
